.privacy {
  padding: .5333rem;

  .tit {
    font-weight: 600;
    font-size: .5333rem;
    text-align: center;
    padding-left:.5333rem;
  }

  .newline {
    margin-bottom: .5333rem;
  }
  .b {
    font-weight: 600;
  }
  .indent {
    text-indent: 1em;
  }

  p {
    text-align: justify;
    line-height: 1.0667rem;
    word-break: break-all;
    word-wrap: break-word;
    margin-top: 0 !important;
    font-size: .4267rem;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .goback {
      width: .5333rem;
      height: 1.0667rem;
      text-align: center;
      line-height: 1.0667rem;
      color: #000;
      transform: rotate(180deg);
    }
  }
  
}
