.Download {
    width: 100%;
    height: 100%;
    text-align: -webkit-center;
    position: absolute;
    background: url('../../assets/download-background.png');
    &-border {
        background-color: rgba($color: #ffffff, $alpha: 0.07);
        border-radius: 5px;
        height: 483.5px;
        width: 311.5px;
        &-padding {
            padding: 60.5px 31px 57.5px 32px;
            background: url('../../assets/download-background.png');
        }
    }
    &-appName {
        padding-top: 80px;
        font-weight: 700;
        color: #fff;
        font-size: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 30px;
            height: 30px;
            padding-right: 10px;
        }
    }
    &-appInfo {
        padding-top: 17.5px;
        padding-bottom: 64px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
    &-button {
        width: 177px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid #fff;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
    }
    &-tip {
        width: 214px;
        height: 176.5px;
        transform: translateX(61.5px);
        &-background {
            background-color: rgba($color: #000, $alpha: 0.7);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }
    }
}